<template>
  <v-main>
    <div class="text-center">
      <v-col cols="12" sm="6" class="pb-0">
        <p class="subtitle-2 font-weight-black ma-0">pf_token_for_ec</p>
        <v-text-field v-model="pf_token_for_ec" single-line outlined dense></v-text-field>
      </v-col>
      <v-btn rounded class="maincolor-bg default_button"
             @click.stop="callApi()">保存</v-btn>
    </div>
  </v-main>
</template>

<script>
export default {
  data() {
    return {
      init: this.storageGet('*'),
      pf_token_for_ec: null,
    };
  },
  created() {
    this.pf_token_for_ec = this.init.pf_token_for_ec
  },
  mounted () {
  },
  methods: {
    callApi() {
      this.storageSave(JSON.stringify({
        'pf_token_for_ec': this.pf_token_for_ec,
      }))
    }
  }
};
</script>

<style scoped>
.fontsize11> >>label {
  font-size: 11px;
}

a {
  color: #333333;
}

</style>
